/* SignUp.css */
.signup-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    height: 100vh; /* 전체 화면 높이 */
}

.title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 60px;
}

.form-input {
    width: 70%;
    height: 40px;
    border: 1px solid gray;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.valid-input {
    border-color: lightgreen;
}

.invalid-input {
    border-color: lightcoral;
}

.input-container {
    display: flex;
    align-items: center;
    width: 70%;
    margin-bottom: 3px;
}

.check-button {
    background-color: #65504F;
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.form-button {
    width: 35%;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 10px;
}
