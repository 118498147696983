/* 전체 화면을 모바일 크기로 설정 */
.chat-room-list {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    height: 100vh; /* 전체 높이 */
    overflow: auto;
    max-width: 375px; /* 최대 너비 (모바일 기준) */
    margin: 0 auto; /* 중앙 정렬 */
}

/* 내비게이션 바 스타일 */
.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: rgb(5, 132, 190);
    padding: 10px 20px;
}

.nav-bar h1 {
    margin: 0;
    font-size: 5vw; /* 화면 너비에 따라 글자 크기 조정 */
}

.nav-bar p {
    margin: 0;
}

/* 버튼 스타일 */
button {
    background-color: rgb(44, 152, 202);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 4vw; /* 화면 너비에 따라 글자 크기 조정 */
}

button:hover {
    background-color: #e4e3e9;
}

/* 입력 필드 스타일 */
input[type="text"] {
    width: calc(100% - 40px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 3.5vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 카테고리 컨테이너 스타일 */
.category-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}

.category-container button {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 2.9vw; /* 화면 너비에 따라 글자 크기 조정 */
    font-size: 11.5px;
}

/* 채팅 리스트 스타일 */
.chat-list {
    padding: 0;
}

/* 채팅 아이템 스타일 */
.chat-item {
    background-color: white;
    padding: 15px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 4vw; /* 화면 너비에 따라 글자 크기 조정 */
}

.chat-item:hover {
    background-color: #f0f0f0;
}

/* 삭제 버튼 스타일 */
.row-back {
    align-items: center;
    background-color: #ff6666;
    justify-content: flex-end;
    display: flex;
    height: 100%;
    padding: 15px;
    border-radius: 5px;
}

.delete-button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

/* 모달 스타일 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* 모달 내용 스타일 */
.modal > div {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%; /* 모바일 화면에 맞춰 너비 조정 */
    max-width: 300px; /* 최대 너비 설정 */
    text-align: center;
    font-size: 4vw; /* 화면 너비에 따라 글자 크기 조정 */
    display: flex;
    flex-direction: column; /* 세로 정렬 */
    align-items: center; /* 중앙 정렬 추가 */
}

/* 버튼 컨테이너 스타일 */
.modal-button-container {
    width: 100%; /* 전체 너비 사용 */
    display: flex;
    justify-content: space-around; /* 버튼 간격 조정 */
    /* margin-top: 20px; 버튼과 텍스트 간의 여백 추가 */
}


/* 드롭다운 버튼 스타일 */
.modal button {
    width: calc(35%); /* 너비를 80%로 설정하여 중앙 정렬 */
    padding: 10px; /* 패딩 설정 */
    border: none;
    border-radius: 5px;
    background-color: #e0d0f7; /* 버튼 배경색 */
    color: white; /* 버튼 글자색 */
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
    cursor: pointer;
    margin: 5px 0; /* 버튼 간 간격 추가 */
    display: flex; /* Flexbox 사용 */
    justify-content: center; /* 중앙 정렬 */
}

.modal button:hover {
    background-color: #e4e3e9; /* 버튼 호버 시 색상 변경 */
}

/* 입력 필드 스타일 */
.modal input[type="text"] {
    width: calc(50%); /* 버튼과 같은 너비 */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 3.5vw; /* 화면 너비에 따라 글자 크기 조정 */
    margin: 10px 0; /* 입력 필드와 버튼 간 간격 추가 */
}



/* 드롭다운 리스트 스타일 */
.dropdown-list {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: absolute;
    z-index: 10;
    width: calc(50%); /* 버튼과 동일한 너비로 설정 */
    max-height: 200px;
    overflow-y: auto;
    display: flex; /* Flexbox 사용 */
    flex-direction: column; /* 세로 정렬 */
    align-items: center; /* 중앙 정렬 */
}

/* 드롭다운 버튼 스타일 */
.dropdown-list button {
    width: 100%; /* 드롭다운 버튼 너비를 100%로 설정 */
    text-align: left;
    padding: 10px;
    border: none;
    background: none;
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
    display: flex; /* Flexbox 사용 */
    justify-content: center; /* 중앙 정렬 */
}

.dropdown-list button:hover {
    background-color: #e4e3e9;
}
/* 하단 네비게이션 바 스타일 */
.bottomNav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    background-color: #F4F3F8;
    z-index: 100; /* 다른 요소 위에 표시 */
}

/* 네비게이션 아이템 스타일 */
.bottomNav .navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

/* 네비게이션 아이콘 스타일 */
.bottomNav .navItem span {
    margin-top: 5px; /* 아이콘과 텍스트 간 간격 */
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 버튼 스타일 (하단 네비게이션) */
.bottomNav button {
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.bottomNav button:hover {
    background-color: #e4e3e9; /* 버튼 호버 시 색상 변경 */
}
