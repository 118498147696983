/* 전체 화면을 모바일 크기로 설정 */
.chat-room-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* 전체 너비 */
    height: 100vh; /* 전체 높이 */
    max-width: 432px; /* 최대 너비 (모바일 기준) */
    max-height: 932px; /* 최대 높이 (iPhone 8 기준) */
    margin: 0 auto; /* 중앙 정렬 */
    border-radius: 20px; /* 둥근 모서리 */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
    background-color: #F4F3F8;
    overflow: hidden; /* 자식 요소가 넘칠 경우 숨김 */
}

/* 내비게이션 바 스타일 */
.navBar {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
    background-color: white; /* 내비게이션 바 배경색 */
}

.navBar h2 {
    flex: 1;
    text-align: center;
    font-size: 4vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 내 메시지 컨테이너 스타일 */
.myMessageContainer {
    display: flex;
    justify-content: flex-end; /* 오른쪽 정렬 */
    margin: 5px 0; /* 메시지 간격 */
}

/* 다른 메시지 컨테이너 스타일 */
.otherMessageContainer {
    display: flex;
    justify-content: flex-start; /* 왼쪽 정렬 */
    margin: 5px 0; /* 메시지 간격 */
}

/* 내 메시지 스타일 */
.myMessage {
    background-color: #007AFF;
    color: white;
    border-radius: 8px;
    padding: 10px;
    max-width: 70%; /* 최대 너비 제한 */
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 다른 메시지 스타일 */
.otherMessage {
    background-color: #f9f9f9;
    color: #000; /* 글자 색상 */
    border-radius: 8px;
    padding: 10px;
    max-width: 70%; /* 최대 너비 제한 */
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 메시지 작성자 스타일 */
.messageAuthor {
    font-weight: bold; /* 닉네임 굵게 표시 */
    margin-bottom: 5px; /* 닉네임과 내용 간 간격 */
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
}
/* 메시지 영역 스타일 */
.messages {
    flex: 1; /* 남은 공간을 차지 */
    overflow-y: auto; /* 세로 스크롤 가능 */
    margin: 10px 0;
    padding: 10px; /* 패딩 추가 */
}


/* 입력 컨테이너 스타일 */
.inputContainer {
    display: flex;
    align-items: center;
    padding: 10px; /* 패딩 추가 */
    background-color: white; /* 배경색 설정 */
    border-top: 1px solid #E0E0E0; /* 상단 경계선 */
}

/* 입력 필드 스타일 */
.inputContainer input {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-right: 5px;
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 전송 버튼 스타일 */
.inputContainer button {
    background-color: #007AFF;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 모달 스타일 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 모달 제목 스타일 */
.modal h3 {
    color: white;
    font-size: 4vw; /* 화면 너비에 따라 글자 크기 조정 */
}

/* 모달 버튼 스타일 */
.modal button {
    margin: 5px;
    padding: 10px;
    background-color: #007AFF;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 3vw; /* 화면 너비에 따라 글자 크기 조정 */
}
