.my-info {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #F4F3F8;
    height: 100vh; /* 전체 높이 */
}

.nav-bar {
    background-color: #F4F3F8;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.nav-bar h1 {
    font-size: 24px;
    font-weight: bold;
}

.nickname-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.nickname-emoji {
    font-size: 40px; /* 이모지 크기 */
    margin-bottom: 10px;
}

.nickname {
    font-size: 24px;
    font-weight: bold;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 100%; /* 컨테이너 너비를 100%로 설정 */
}

.button-row {
    display: flex;
    justify-content: space-between;
    width: 100%; /* 버튼이 가로로 늘어날 수 있도록 너비를 100%로 설정 */
    max-width: 600px; /* 최대 너비 설정 (필요에 따라 조정) */
}

.action-button {
    /* 기존 스타일 유지 */
    display: flex;
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 10px;
    margin: 5px; /* 여백을 추가하여 버튼 간 간격을 확보 */
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: black; /* 버튼 글자 색상 검정색으로 설정 */
    width: calc(100% - 10px); 
}
.action-button svg {
    margin-right: 8px; /* 아이콘과 텍스트 사이의 간격 조정 */
}




/* 모달 스타일 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* 모달 내용 스타일 */
.modal > div {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 60%; /* 모바일 화면에 맞춰 너비 조정 */
    max-width: 400px; /* 최대 너비 설정 */
    text-align: center;
    font-size: 4vw; /* 화면 너비에 따라 글자 크기 조정 */
    display: flex;
    flex-direction: column; /* 세로 정렬 */
    align-items: center; /* 중앙 정렬 추가 */
}

/* 제목 스타일 */
.modal h2 {
    margin-bottom: 15px; /* 제목과 입력 필드 사이 여백 */
    font-size: 20px;
}

/* 입력 필드 스타일 */
.modal input[type="text"] {
    width: calc(50%); /* 버튼과 같은 너비 */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 3.5vw; /* 화면 너비에 따라 글자 크기 조정 */
    margin: 10px 0; /* 입력 필드와 버튼 간 간격 추가 */
}

/* 버튼 컨테이너 스타일 */
.modal-button-container {
    display: flex;
    justify-content: space-between; /* 버튼 간격 조정 */
    width: calc(60%); /* 버튼 컨테이너 너비를 100%로 설정 */
}

/* 드롭다운 버튼 스타일 */
.modal button {
    width: calc(50%);
    flex: 1; /* 버튼이 동일한 비율로 나누어짐 */
    padding: 10px; /* 패딩 설정 */
    border: none;
    border-radius: 5px;
    background-color: #6200ea; /* 버튼 배경색 */
    color: white; /* 버튼 글자색 */
    font-size: 4vw; /* 화면 너비에 따라 글자 크기 조정 */
    cursor: pointer;
    margin: 5px; /* 버튼 간 간격 추가 */
    display: flex;
}

.modal button:hover {
    background-color: #e4e3e9; /* 버튼 호버 시 색상 변경 */
}


.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    background-color: #F4F3F8;
}

.bottom-nav button {
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
}

.request-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.request-item button {
    background-color: #4290f7;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
